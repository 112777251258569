var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "rulesOptions",
    },
    [
      _vm.channelList.length
        ? [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.channelNo,
                  callback: function ($$v) {
                    _vm.channelNo = $$v
                  },
                  expression: "channelNo",
                },
              },
              _vm._l(_vm.channelList, function (item, index) {
                return _c("el-tab-pane", {
                  key: index,
                  attrs: { label: item.channelName, name: item.channelNo },
                })
              }),
              1
            ),
            _c("div", { staticClass: "content-box" }, [
              _c(
                "div",
                { staticClass: "menu" },
                [
                  _c(
                    "el-menu",
                    { attrs: { "default-active": _vm.path, router: true } },
                    [
                      _vm._l(_vm.rulesList, function (item, index) {
                        return [
                          _c(
                            "el-menu-item",
                            { key: index, attrs: { index: item.url } },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(item.menuName))]
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.isShow
                ? _c(
                    "div",
                    { staticClass: "config" },
                    [
                      _c("router-view", {
                        ref: "childrenRef",
                        attrs: { channelNo: _vm.channelNo },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        : _vm.emptyFlag
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "font-size": "18px",
                "text-align": "center",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/home/empty.png"),
                  alt: "",
                },
              }),
              _c("div", [_vm._v("暂无权限，请联系管理员")]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }