<template>
  <!-- 业务规则配置 -->
  <div v-loading="pageLoading" class="rulesOptions">
    <template v-if="channelList.length">
      <el-tabs v-model="channelNo" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        >
        </el-tab-pane>
      </el-tabs>
      <div class="content-box">
        <div class="menu">
          <el-menu :default-active="path" :router="true">
            <template v-for="(item, index) in rulesList"
              ><el-menu-item :key="index" :index="item.url">
                <span slot="title">{{ item.menuName }}</span>
              </el-menu-item></template
            >
          </el-menu>
        </div>
        <div v-if="isShow" class="config">
          <router-view ref="childrenRef" :channelNo="channelNo" />
        </div>
      </div>
    </template>
    <div
      v-else-if="emptyFlag"
      style="width: 100%; font-size: 18px; text-align: center"
    >
      <img src="~@/assets/images/home/empty.png" alt />
      <div>暂无权限，请联系管理员</div>
    </div>
  </div>
</template>

<script>
import YTable from "@/components/Ytable/index";
import { getCurrentUserChannelInfoAPI } from "@/api/lib/api.js";
export default {
  components: { YTable },
  data() {
    return {
      pageLoading: true,
      emptyFlag: false,
      isShow: false,
      path: null,
      channelNo: "",
      channelList: [],
      rulesList: [],
    };
  },
  methods: {
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI()
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.channelList = res.data;
            this.channelNo = res.data[0]?.channelNo;
            if (!res.data.length) {
              this.$message.error("暂无权限，请联系管理员");
              this.emptyFlag = true;
              this.pageLoading = false;
              return;
            }
            this.pageLoading = false;
            this.isShow = true;
          }
        })
        .catch(() => {
          this.pageLoading = false;
        });
    },
    //点击渠道tab项,且阻止重复请求
    handleClick(val) {
      this.$refs.childrenRef.tabClick(0, "阻止请求");
    },
    selectMenu(list, name) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].menuName != name) {
          if (Array.isArray(list[i].subMenuTrees)) {
            let menu = this.selectMenu(list[i].subMenuTrees, name);
            if (menu) {
              return menu;
            }
          }
        } else {
          return list[i].subMenuTrees;
        }
      }
    },
  },
  mounted() {
    let index = this.$route.path.lastIndexOf("/") + 1;
    this.path = this.$route.path.slice(index);
    this.getQueryChannelList();
    let list = JSON.parse(sessionStorage.getItem("menuList"));
    this.rulesList = this.selectMenu(list.subMenuTrees, "业务规则配置");
  },
};
</script>

<style lang="scss" scoped>
.rulesOptions {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .el-tabs {
    background-color: #fff;
    flex-shrink: 0;
  }
  .content-box {
    flex: 1;
    width: 100%;
    display: flex;
  }
  .menu {
    width: 200px;
    height: 100%;
    background-color: #fff;
  }
  .config {
    height: 100%;
    width: calc(100% - 222px);
    margin-left: 20px;
    background-color: #fff;
  }
}
</style>
